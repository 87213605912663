import axios from 'axios';
import errorChecker from './errorchecker';
import persistentStore from '../stores/persistentStore';
import {ICustomer} from "../interfaces/ICustomer";

const customerRequests = () => {

    const add = async (customer: ICustomer) => {
        const path = `/users/${customer.user_id}/customers`;
        const data = customer;
        const config = persistentStore.authHeader;

        return axios.post(persistentStore.apiUrl + path, data, config).then(
            (response) => {
                if (response.request.status !== 400) {
                    return response;
                }

                return JSON.parse(response.request.response);
            }).then(response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.customer) {
                console.log('The response did not contain the created customer');
                // throw Error('The response did not contain the created customer')
                return response.data;
            }
            return data.customer;
        });
    };

    const getByPublicId = async (customerPublicId: string) => {
        const path = `/customers/${customerPublicId}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.customer) {
                console.log('The response did not contain customers');
                throw Error('The response did not contain customers');
            }

            return data.customer;
        });
    };

    const getCardsByPublicId = async (customerPublicId: string) => {
        const path = `/customers/${customerPublicId}/cards`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.cards) {
                console.log('The response did not contain cards');
                throw Error('The response did not contain cards');
            }

            return data.cards;
        });
    };

    const getReadOnlyView = async (publicId: string) => {
        const path = `/customers/customer_view/${publicId}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.customer) {
                console.log('The response did not contain customers');
                throw Error('The response did not contain customers');
            }

            return data.customer;
        });
    };

    const cardsForStatusPage = async (customerPublicId: string) => {
        const path = `/customers/${customerPublicId}/status-cards`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.cards) {
                throw new Error('The response did not contain customers');
            }

            return data;
        });
    };

    const getAll = async () => {
        const path = `/users/${persistentStore.user?.id}/customers`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data.data;
            if (!data.customers) {
                throw new Error('The response did not contain customers');
            }

            persistentStore.setCustomers(data.customers);
            return data.customers;
        });
    };

    const update = async (customer: ICustomer) => {
        const path = `/customers/${customer.public_id}`;
        const config = persistentStore.authHeader;

        return axios.put(persistentStore.apiUrl + path, customer, config).then(
            response => errorChecker(response)).then(response => {
            const data = response.data;

            // update the customer in the store collection of all customers
            persistentStore.updateCustomer(data.data.customer);

            // return data so the requesting party can check for 'success' as well
            return data;
        });
    };

    const updateLogo = async (logoFormData: any) => {
        const path = `/customers/uploadLogo`;
        const config = persistentStore.authHeader;

        return axios.post(persistentStore.apiUrl + path, logoFormData, config).then(
            response => errorChecker(response)).then(response => {
            if (response.data.success) {
                return response.data;
            }

            throw new Error('An error occurred while uploading the logo');
        });
    };

    const clearLogo = async (customerId: number) => {

        const path = `/customers/clearCustomerLogo`;
        const config = persistentStore.authHeader;
        const data = {id: customerId};

        return axios.post(persistentStore.apiUrl + path, data, config).then(
            response => errorChecker(response)).then(response => {
            if (response.data.success) {
                return response.data;
            }

            throw new Error('An error occurred while removing the logo');
        });
    };

    const revive = async (customerEmail: string) => {
        const path = `/customers/revive`;
        const config = persistentStore.authHeader;
        const data = {action: 'revive', email: customerEmail};

        return axios.put(persistentStore.apiUrl + path, data, config).then(
            response => errorChecker(response)).then(response => {
            const customer = response.data.data.customer;

            // add the customer to the store again
            persistentStore.updateCustomer(customer);

            return response.data;
        });
    };

    const del = async (customerPublicId: string) => {
        const path = `/users/${persistentStore.user?.id}/customers/${customerPublicId}`;
        const config = persistentStore.authHeader;

        return axios.delete(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            return response.data;
        });
    };

    const getCustomerMails = async (customerPublicId: number) => {
        const path = `/customers/get-mails/${customerPublicId}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            return response.data;
        });
    };

    const sendCustomerMails = async (mailId: number, customerPublicId: string) => {
        const path = `/customers/send-mails/${mailId}/${customerPublicId}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {
            return response.data;
        });
    };

    interface ICustomerTextEdit {
        customer_text: string,
        id: number
    }

    const saveCustomerText = async (props: ICustomerTextEdit) => {
        const path = `/customers/save-customer-text`;
        const config = persistentStore.authHeader;

        return axios.put(persistentStore.apiUrl + path, props, config).then(
            response => errorChecker(response)).then(response => {
            if (response.data.success) {
                return response.data;
            }

            throw new Error('An error occurred while adding customer text');
        });
    };

    const deleteCustomerGroup = async (groupId: number) => {
        const path = `/customer-groups/${groupId}`;
        const config = persistentStore.authHeader;

        return axios.delete(persistentStore.apiUrl + path, config).then(
            response => {
                if (response.request.status === 200) {
                    return response.data.customerGroup;
                }

                if (response.request.status === 400) {
                    console.error(response.data);
                    throw new Error('The response did not contain customer Groups');
                }

                console.error('Response was not returned with HTTP code 200');
                console.error(response);
            });
    };

    const addCustomerGroup = async (groupname: string, customerIds: string[]) => {
        const path = `/customer-groups`;
        const config = persistentStore.authHeader;
        const postData = {
            'class_name': groupname,
            'customers': customerIds,
            'user_id': persistentStore.user?.id,
        };

        return axios.post(persistentStore.apiUrl + path, postData, config).then(
            response => {
                if (response.request.status !== 400) {
                    return response;
                }

                if (response.request.status === 400) {
                    throw new Error('The response did not contain customer Groups');
                }

                return JSON.parse(response.request.response);
            }).then(response => errorChecker(response));
    };

    const customerGroupsAll = async () => {
        const path = `/customer-groups/?user_id=${persistentStore.user?.id}`;
        const config = persistentStore.authHeader;

        return axios.get(persistentStore.apiUrl + path, config).then(
            response => errorChecker(response)).then(response => {

            const data = response.data.data;

            // change property customers to be an array of public_id's
            data.customerGroups = data.customerGroups.map((group: any) => {
                group.customers = group.customers.map((customer: any) => customer.public_id);
                return group;
            });

            if (!data.customerGroups) {
                throw new Error('The response did not contain customer Groups');
            }

            return data.customerGroups;
        });
    };

    return {
        add,
        addCustomerGroup,
        cardsForStatusPage,
        clearLogo,
        customerGroupsAll,
        del,
        deleteCustomerGroup,
        getAll,
        getByPublicId,
        getCardsByPublicId,
        getCustomerMails,
        getReadOnlyView,
        revive,
        saveCustomerText,
        sendCustomerMails,
        update,
        updateLogo,
    };
};

export const customers = customerRequests();
